import React, { useContext, useEffect, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import Block from "../configurable/Block"
import Col8 from "../grid/Col8"
import Col4 from "../grid/Col4"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import HighlightBlock from "../configurable/HighlightBlock"
import Icon from "../olc-framework/Icon"
import BlockCTA from "../configurable/BlockCTA"
import TickList from "../configurable/TickList"
import { imageNodesFilter, markdownNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Image from "../configurable/Image"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import Col5 from "../grid/Col5"
import Col3 from "../grid/Col3"
import Col2 from "../grid/Col2"
import "./about.scss"
import "./bolds.scss"
import Section from "../configurable/Section"

import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { GetModal } from "../configurable/Modal"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col11 from "../grid/Col11"
import Col12 from "../grid/Col12"
import Col9 from "../grid/Col9"

const content = {
  vetus: {
    title: 'Vetus Cepulis',
    content: (
      <>
        <div className="modal_content_container modal_columns staff_modal">
          <div style={{ marginLeft: 0 }} className="modal_copy_container">
            <p style={{ lineHeight: 1.2 }}>I am the Technical Director. Previously, I was the commissioning manager, I have over 10 years of experience in the solar and renewables industry where I was in charge of designing documentation ensuring compliance with all local AHJ and national codes, mechanical and electrical specifications, safety items, dead and live testing. Going right back, I started as a site supervisor for several UK companies.  </p>
          </div>
          <div className="round-cropper bitmoji" >
            <img className="rounded-image" src={require('../../images/bitmoji/vetus.png')} />
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
            <a href="mailto:vetus.cepulis@get-uk.com">Ask me a question!</a>
          </button> */}
          <button className="modal_btn">
            <a href="https://www.linkedin.com/in/vetus-cepulis-935b2411a/" target="_blank" >Follow me on LinkedIn</a>
          </button>
        </div>
      </>
    )
  },
  ryan: {
    title: 'Ryan Whittaker',
    content: (
      <>
        <div className="modal_content_container modal_columns staff_modal">
          <div style={{ marginLeft: 0 }} className="modal_copy_container">
            <p style={{ lineHeight: 1.2 }}>I'm originally a traditional roofer by trade, who diversified into the renewables sector in 2009 after running a successful business that employed four roofing teams and spanned throughout the East Midlands/South Yorkshire and Lincolnshire for eight years. I enjoy the diversity and challenges of the multiple projects undertaken at Green Energy Together. I am currently working on numerous ventures, ranging from domestic housing associations, commercial rooftop and ground-mounted solar farms exceeding 150MW! </p><p>
              Outside of work? I'm a big fan of watching and taking part in motorcycle racing, spending time with family - especially teaching the kids to ride, race and play football.
            </p>
          </div>
          <div className="round-cropper bitmoji" >
            <img className="rounded-image" src={require('../../images/bitmoji/ryan.png')} />
          </div>
        </div>
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href="mailto:ryan.whittaker@get-uk.com">Ask me a question!</a>
          </button>
        </div>
      </>
    )
  },
  // simon: {
  //   title: 'Simon Bugg',
  //   content: (
  //     <>
  //       <div className="modal_content_container modal_columns staff_modal">
  //         <div style={{ marginLeft: 0 }} className="modal_copy_container">
  //           <p style={{ lineHeight: 1.2 }}>Responsible for the Commerical Sales & Marketing teams at Green Energy Together promoting and offering commercial solar solutions with over 15 years of experience in the utility and renewable market. I am very passionate about the environment and nature, dubbed an “Eco Warrior” by my family from a very young age. On the weekends I enjoy having a swim, playing football, and climbing trees with my son. For my sins, I am an avid supporter of Southampton FC and enjoy live music, swimming and attempting to play golf.</p>
  //         </div>
  //         <div className="round-cropper bitmoji" >
  //           <img className="rounded-image" src={require('../../images/bitmoji/simon.png')} />
  //         </div>
  //       </div>
  //       <div className="modal_btn_container">
  //         {/* <button className="modal_btn">
  //           <a href="mailto:simon.bugg@get-uk.com">Ask me a question!</a>
  //         </button> */}
  //         <button className="modal_btn">
  //           <a href="https://www.linkedin.com/in/simon-bugg-06239b1a/" target="_blank" >Follow me on LinkedIn</a>
  //         </button>
  //       </div>
  //     </>
  //   )
  // },
  nick: {
    title: 'Nick Elbourne',
    content: (
      <>
        <div className="modal_content_container modal_columns staff_modal">
          <div style={{ marginLeft: 0 }} className="modal_copy_container">
            <p style={{ lineHeight: 1.2 }}>I have over 15 years of experience in electrical engineering and infrastructure design. Before moving into renewable technology, I designed and managed infrastructure installation projects for financial institutions. During my years, I have gained a strong understanding of electrical infrastructure and process management which is implemented.
            </p><p>
              I have worked on domestic, commercial and industrial solar installations throughout the UK. My experience also includes working on both LV and MV networks. This allowed me to manage the installation of over 4MW of commercial solar systems, 175MW of industrial ground-mounted solar installations and over 50MW of domestic solar PV installations. As Managing Director, I use my knowledge and experience to guide the team into the delivery of solar installations to the highest standard. During my free time, I enjoy playing golf and supporting Arsenal F.C.
            </p>
          </div>
          <div className="round-cropper bitmoji" >
            <img className="rounded-image" src={require('../../images/bitmoji/nick.png')} />
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
            <a href="mailto:nick.elbourne@get-uk.com">Ask me a question!</a>
          </button> */}
          <button className="modal_btn">
            <a href="https://www.linkedin.com/in/nick-elbourne-30225550/" target="_blank" >Follow me on LinkedIn</a>
          </button>
        </div>
      </>
    )
  },
  clive: {
    title: 'Clive Houlston',
    content: (
      <>
        <div className="modal_content_container modal_columns staff_modal">
          <div style={{ marginLeft: 0 }} className="modal_copy_container">
            <p style={{ lineHeight: 1.2 }}>Professional electrical engineer with 40 years of experience; working over the last fifteen years in sales and marketing management. Involved in electrical engineering in addition to heating, air conditioning and refrigeration. Applying new technologies to practical application. Working with The BFA on developing ethical franchising for the benefit of potential business owners.</p>
          </div>
          <div className="round-cropper bitmoji" >
            <img className="rounded-image" src={require('../../images/bitmoji/clive.png')} />
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
            <a href="mailto:clive.houlston@get-uk.com">Ask me a question!</a>
          </button> */}
          <button className="modal_btn">
            <a href="https://www.linkedin.com/in/clive-c-houlston-9b26013/" target="_blank" >Follow me on LinkedIn  </a>
          </button>
        </div>
      </>
    )
  },
  karl: {
    title: 'Karl Gedney',
    content: (
      <>
        <div className="modal_content_container modal_columns staff_modal">
          <div style={{ marginLeft: 0 }} className="modal_copy_container">
            <p style={{ lineHeight: 1.2 }}>My working life has revolved around retail sales and marketing for 28 years, working within a range from large home building projects to full house renovations and extensions, leading onto 15 years working in the renewable energy industry.</p><p>
              Managing sales, marketing and installation of solar water heating systems to residential and commercial, this then went onto to Air source and ground source heat pumps with the initial launch of the Governments Renewable heat incentive.
              Since 2010 I have been involved in the solar photovoltaic industry, managing sales, marketing and installation within the residential market.</p><p>
              In my last few years, I am purely focused on the customers' journey, so as sales and marketing director my aim is to make solar affordable for all residential customers, but with offering the highest quality products and services, I believe we all have to work together to make a greener future. I have a big passion for Formula one and like to support as much as I can, I enjoy watching and supporting Rugby with my sons who play. I like to challenge myself and keep as fit as much as I can through activities such as hiking and climbing the occasional mountain. </p>
          </div>
          <div className="round-cropper bitmoji" >
            <img className="rounded-image" src={require('../../images/bitmoji/karl_new_bitmoji.png')} />
          </div>
        </div>
        <div className="modal_btn_container">
          {/* <button className="modal_btn">
            <a href="mailto:karl.gedney@get-uk.com">Ask me a question!</a>
          </button> */}
          <button className="modal_btn">
            <a href="https://www.linkedin.com/in/karl-gedney-451b0516b/" target="_blank" >Follow me on LinkedIn</a>
          </button>
        </div>
      </>
    )
  },
}


const centerstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const AboutPage = ({ markdownNodes, imageNodes }) => {
  const { setCustomerType } = useContext(CustomerTypeContext)

  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  //END MODAL LOGIC


  return (
    <div>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={modalContent?.title || ''}
      >
        {modalContent?.content}
      </GetModal>
      <div className="products-and-warranties content-page">
        <Hero imageUrl="/images/banner_new_2.png" compact>
          <Heading level={1} underlined>
            About us
          </Heading>
        </Hero>
        <Block >
          <div className="container u-layout--indent about-page">

            {/* STARTS OUT HISTORY */}
            <div >
              <div className="row">
                <Col8>
                  <Heading level={2}>Who we are</Heading>
                </Col8>
              </div>
              <div className="row bold-formatting">
                <Col8>
                  <p>
                    It all began as a family solar panel business in 2009 in Hertfordshire, England. Over the years, Green Energy Together has grown considerably, both <strong>fuelling and sustaining</strong> the increasing awareness and adoption of renewable energy sources. Our team has many years of experience in the renewable industry across multiple technologies, including <strong>solar PV, solar thermal, energy storage and air source</strong>. Our background covers all facets of solar - from large-scale ground mount to <strong>bespoke designs</strong> and several historic British landmarks.
                  </p>
                  <p>
                    Our team has designed, engineered, installed and maintained tens of thousands of domestic rooftops and commercial solar power systems across the UK for over a decade. In 2019 Green Energy Together decided to tackle the domestic solar market with a clear vision - that <strong>solar power systems should be for everyone</strong>. That is why we have cut out the middleman, offering solutions for residential customers at prices usually found in industrial and commercial settings. All while ensuring investor-standard warranty and delivery.
                  </p>
                  <p>
                    As one of the largest installers in the country, our scale allows us to <strong>offer unbeatable prices</strong>. We not only deal directly with manufacturers, but we also have in-house Design, Installation, Commissioning and Maintenance teams, ensuring we offer you the services needed.
                  </p>

                  <Image
                    src={require('../../images/getbuilding.png')}
                    title="Green Energy Together office" />

                </Col8>
                {/* <Col4>
                <div>
                <Image src={require('../../images/chest1x1.jpg')} title="Green Energy Together office"/>

                </div>
              </Col4> */}

                <Col4>
                  <div>
                    <HighlightBlock
                      title="Key facts"
                      actionText="Jump to products area"
                    >
                      <li>
                        <Icon alias="crane" />
                        Established in 2009
                      </li>
                      <li>
                        <Icon alias="energy-transform" />
                        400+ MW of energy generated
                      </li>
                      <li>
                        <Icon alias="solar-house-2" />
                        UK wide installations coverage
                      </li>
                    </HighlightBlock>
                  </div>
                </Col4>

              </div>
              {/* <div className="row" style={{ marginTop: 35 }}>
                <Col8>

                  <p >Our team has designed, engineered, installed and maintained tens of thousands of domestic rooftops and commercial solar power systems across the UK for over a decade. In 2019 Green Energy Together decided to tackle the domestic solar market with a clear vision - that solar power systems should be for everyone. That is why we have cut out the middleman, offering solutions for residential customers at prices usually found in industrial and commercial settings. All while ensuring investor-standard warranty and delivery. </p>
                </Col8>
                <Col4>
                     <Image src={require('../../images/staff/get-staff-selfie.png')} 
                     title="Green Energy Together office" 
                     caption="The GET team at our office in Ware, Hertfordshire" />
                  </Col4>

              </div> */}

            </div>
            {/* ENDS OUR HISTORY  */}

            {/* STARTS MISSION  */}
            <div>

              <div className="row">
                <Col8
                  style={{
                    borderTop: "1px solid #d1d1d1",
                    paddingTop: 52,
                  }}
                >
                  {/* <Heading level={3}>The GET way</Heading> */}
                </Col8>
              </div>
              <div className="row">
                <Col8>
                  {/* <p >As one of the largest installers in the country, our scale allows us to offer unbeatable prices. We not only deal directly with manufacturers, we have in-house Design, Installation, Commissioning and Maintenance teams, ensuring we offer you the services needed.</p> */}


                  <Icon alias="solar-fitting" style={{ fontSize: 90, color: "#051c3f", minWidth: "70px", marginBottom: "15px", marginTop: "15px" }} />
                  <Heading level={4} >Our Mission</Heading>
                  <p className="bold-formatting">
                    A company at the forefront of <strong>energy sustainability</strong>, striving to provide solar and storage systems of the highest quality, helping to <strong>reduce</strong> the impact climate change is having on our planet, making solar <strong>affordable</strong> for every household and business.

                  </p>


                  <Icon alias="solar-energy" style={{ fontSize: 90, color: "#051c3f", minWidth: "70px", marginBottom: "15px", marginTop: "15px" }} />
                  <Heading level={4}>Our Vision</Heading>
                  <p className="bold-formatting">
                    Our Vision is to help people reduce their environmental <strong>impact</strong> through the use of <strong>solar energy</strong>
                  </p>
                </Col8>
                <Col4>
                  <HighlightBlock
                    title="In numbers"

                    actionText="Jump to products area"
                  >
                    <li>
                      <Icon alias="buildings" />
                      40,326+ systems installed
                    </li>
                    <li>
                      <Icon alias="eco-friendly" />
                      36,950+ tons of CO2 saved
                    </li>
                    <li>
                      <Icon alias="sun" />
                      1.5m + equivalent of trees saved
                    </li>
                  </HighlightBlock>
                </Col4>


              </div>

            </div>
            {/* ENDS VISION */}

            {/* STARTS TEAM  */}
            <div style={{ marginTop: "70px" }}>

              <div className="row">
                <Col8>
                  <Heading level={3}>Meet the Team</Heading>
                </Col8>
              </div>
              <div className="row">
                <Col8>
                  <p className="bold-formatting">
                    The Green Energy Together team has extensive experience in the renewable industry across a variety of technologies, including solar PV, solar thermal, energy storage and air source.
                  </p>
                  <div className="row staff">
                    <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/ryan_plus2.png')}
                          onClick={() => onOpenModalFor('ryan')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Ryan Whittaker
                      </h3>
                      <h4 className="staff__item__role">
                        Operations Director
                      </h4>
                    </div>
                    <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/clive_plus.png')}
                          onClick={() => onOpenModalFor('clive')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Clive C Houlston
                      </h3>
                      <h4 className="staff__item__role">
                        Customer Service Director
                      </h4>
                    </div>


                    <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/nick_plus2.png')}
                          onClick={() => onOpenModalFor('nick')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Nick Elbourne
                      </h3>
                      <h4 className="staff__item__role">
                        Managing Director
                      </h4>
                    </div>
                    <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/karl_plus.png')}
                          onClick={() => onOpenModalFor('karl')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Karl Gedney
                      </h3>
                      <h4 className="staff__item__role">
                        Domestic Sales and Marketing Director
                      </h4>
                    </div>
                    <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/vetus_plus2.png')}
                          onClick={() => onOpenModalFor('vetus')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Vetus Cepulis
                      </h3>
                      <h4 className="staff__item__role">
                        Technical Director
                      </h4>
                    </div>
                    {/* <div className="staff__item">
                      <button className="staff__item__button">
                        <img
                          className="staff__item__button__image"
                          src={require('../../images/staff/simon_plus.png')}
                          onClick={() => onOpenModalFor('simon')}
                        />
                      </button>
                      <h3 className="staff__item__name">
                        Simon Bugg
                      </h3>
                      <h4 className="staff__item__role">
                        Commercial Sales and Marketing Director
                      </h4>
                    </div> */}
                  </div>
                </Col8>
                <Col4>
                  <HighlightBlock
                    title="Connect with us"
                  >
                    <p  >

                      Follow us on social media for environmental tips, updates and promotions!
                    </p>
                    <div className="about__sm-icons" style={{ justifyContent: "space-around" }} >
                      <button>
                        <img src={require('../../images/sm_icons/fb.svg')} alt="facebook" onClick={() => window.open("https://www.facebook.com/greenenergytogether/", "_blank")} />
                      </button>
                      <button>
                        <img src={require('../../images/sm_icons/linkedin.svg')} alt="facebook" onClick={() => window.open("https://www.linkedin.com/company/get-uk", "_blank")} />
                      </button>
                      <button>
                        <img src={require('../../images/sm_icons/insta.svg')} alt="facebook" onClick={() => window.open("https://www.instagram.com/green.energy.together/", "_blank")} />
                      </button>
                      <button>
                        <img src={require('../../images/sm_icons/twitter.svg')} alt="facebook" onClick={() => window.open("https://twitter.com/GET_UK_solar", "_blank")} />
                      </button>
                    </div>

                  </HighlightBlock>
                </Col4>



              </div>


            </div>
            {/* ENDS TEAM */}

            {/* STARTS PROJECTS  */}
            <div style={{ marginTop: "70px" }}>

              <div className="row">
                <Col11>
                  <Heading level={3}>Our work</Heading>
                </Col11>
              </div>
              <div className="row">
                <Col11>
                  <p className="bold-formatting">
                    Over the decade, we’ve worked on multiple projects in multiple locations such as Paddington Train Station, Kings College Hospital, South Cambridgeshire Council Housing, Moss Electrical and Board Oak farm to name a few.  </p>
                  <p>
                    Below you’ll be able to see more of our work, customer reviews and testimonials:
                  </p>
                  <div className="row">
                    <Col3>
                      <div style={{ marginBottom: "50px", marginTop: "70px" }}>
                        <Image
                          src={require('../../images/moss_about.png')}
                          title="Moss Electrical"
                        />
                        <BlockCTA url="/moss/" className="about-CTA-button">
                          Moss Electrical
                        </BlockCTA>
                      </div>
                    </Col3>
                    <Col3>
                      <div style={{ marginBottom: "50px", marginTop: "70px" }}>
                        <Image
                          src={require('../../images/paddington_about.png')}
                          title="Installation at Paddington Station"
                        />
                        <BlockCTA url="/project/paddington-station/" className="about-CTA-button">
                          Paddington Station
                        </BlockCTA>
                      </div>
                    </Col3>
                    <Col3>
                      <div style={{ marginBottom: "50px", marginTop: "70px" }}>
                        <Image
                          src={require('../../images/king_about.png')}
                          title="Installation at King's College Hospital"
                        />
                        <BlockCTA url="/project/kings-college-hospital/" className="about-CTA-button" >
                          King's College Hospital
                        </BlockCTA>
                      </div>
                    </Col3>
                    <Col3>
                      <div style={{ marginBottom: "50px", marginTop: "70px" }}>
                        <Image
                          src={require('../../images/broadoak_about.png')}
                          title="Broad Oak Farm"
                        />
                        <BlockCTA url="/project/broad-oak-farm/" className="about-CTA-button">
                          Broad Oak Farm
                        </BlockCTA>
                      </div>
                    </Col3>
                  </div>
                </Col11>
              </div>


            </div>
            {/* ENDS PROJECTS */}


          </div>
        </Block>
      </div>
    </div>
  )
}

export default AboutPage